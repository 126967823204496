import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppButton, Avatar, Modal, PageContainer, Text } from '../../components';
import { BackButton, Container, Wrapper, Bottom, DoctorRow } from './styled';

import arrowPath from '../../assets/icons/back-arrow.svg';
import { Select } from './Select';
import { DoctorType, SUBSCRIPTION_TYPE } from '../../types';
import { API, COLORS } from '../../const';

export const SubscriptionPage = () => {
  const [pickerValue, setPickerValue] = useState({
    type: '12',
  });
  const [type, setType] = useState(SUBSCRIPTION_TYPE.PREMIUM);
  const [token, setToken] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [targetId, setTargetId] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [open, setOpen] = useState(false);
  const [doctor, setDoctor] = useState<DoctorType>();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const getDoctor = async (id: string) => {
    try {
      const response = await fetch(`${API}/doctors/${targetId}`, {
        method: 'GET',
      });

      if (response.status === 200) {
        const data = await response.json();
        setDoctor(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createPayment = async () => {
    let url = `${API}/subscriptions?subscription_type=${type}`;
    if (targetId) {
      url += `&target_id=${targetId}`;
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const data: string = await response.json();
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancelSubscriptions = async () => {
    try {
      const response = await fetch(`${API}/subscriptions/${subscriptionId}/cancel`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        navigate('/payment/complete?status=subscription_cancelled');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renewSubscriptions = async () => {
    try {
      const response = await fetch(`${API}/subscriptions/${subscriptionId}/renew`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data: string = await response.json();
        const url = `${API}/payments/yookassa?confirmation_token=${data}`;
        window.location.assign(url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const startYookassa = async () => {
    try {
      const confirmationToken = await createPayment();
      const url = `${API}/payments/yookassa?confirmation_token=${confirmationToken}`;
      window.location.assign(url);
    } catch (e) {
      console.log(e);
    }
  };

  const onBack = () => {
    navigate('/');
  };

  const onChange = (value: { type: string }) => {
    setPickerValue(value);
  };

  useEffect(() => {
    window.localStorage.removeItem('PAYMENT_STATUS');
    if (searchParams.has('subscription_type')) {
      const type = searchParams.get('subscription_type');
      setType(type as SUBSCRIPTION_TYPE);
    }
    if (searchParams.has('token')) {
      const token = searchParams.get('token');
      setToken(token || '');
    }
    if (searchParams.has('target_id')) {
      const id = searchParams.get('target_id');
      setTargetId(id || '');
    }
    if (searchParams.has('subscription_id')) {
      const id = searchParams.get('subscription_id');
      setSubscriptionId(id || '');
    }
    if (searchParams.has('is_expired')) {
      setIsExpired(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (targetId) {
      getDoctor(targetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId]);

  return (
    <PageContainer isFull>
      <Container>
        <Wrapper>
          <div style={{ position: 'relative' }}>
            <BackButton onClick={onBack}>
              <img src={arrowPath} alt="arrow" />
            </BackButton>
            <Text pb={16} size={16} weight={600} textAlign="center">
              {!!subscriptionId ? 'Подписка' : 'Покупка'}
            </Text>
          </div>

          <Text size={14} lineHeight={21} weight={600}>
            {type === SUBSCRIPTION_TYPE.PREMIUM ? 'Премиум подписка' : 'Подписка на врача:'}
          </Text>

          {doctor && (
            <DoctorRow>
              <Avatar size="small" path={doctor.avatar || ''} />
              <div>
                <Text size={14} weight={600}>
                  {doctor.last_name} {doctor.first_name} {doctor.patronymic}
                </Text>
                <Text size={14}>
                  {doctor.subname} {doctor.workplace ? ` | ${doctor.workplace}` : ''}
                </Text>
              </div>
            </DoctorRow>
          )}
          <Text size={14} lineHeight={21}>
            {type === SUBSCRIPTION_TYPE.PREMIUM
              ? 'Проконсультируйтесь с узко или широко профильным врачом по вашему состоянию прямо сейчас. '
              : 'Проконсультируйтесь с врачом по вашему состоянию прямо сейчас. '}
            Консультация происходит онлайн в режим общения через чат.
          </Text>
          <Text size={14} lineHeight={21} weight={600}>
            Что входит?
          </Text>
          {type === SUBSCRIPTION_TYPE.PREMIUM && (
            <ul>
              <li style={{ fontSize: 14, lineHeight: '21px' }}>
                Неограниченные онлайн консультации
              </li>
              <li style={{ fontSize: 14, lineHeight: '21px' }}>
                Полный доступ ко всем функциям приложения
              </li>
              <li style={{ fontSize: 14, lineHeight: '21px' }}>Поддержка 24/7</li>
            </ul>
          )}
          {type === SUBSCRIPTION_TYPE.DOCTOR && (
            <ul>
              <li style={{ fontSize: 14, lineHeight: '21px' }}>
                Неограниченные онлайн консультации с врачом
              </li>
            </ul>
          )}

          <Text pt={8} size={14} lineHeight={21} weight={600}>
            Как пользоваться?
          </Text>
          <div>
            <Text size={14} lineHeight={21} weight={600}>
              1. Оплатите подписку.
            </Text>
            <Text size={14} lineHeight={21}>
              Подписка становится доступна сразу после оплаты и действует в течение указанного срока
              с момента покупки.
            </Text>
          </div>

          {type === SUBSCRIPTION_TYPE.PREMIUM && (
            <div>
              <Text size={14} lineHeight={21} weight={600}>
                2. Сформулируйте проблему.
              </Text>
              <Text size={14} lineHeight={21}>
                Опишите свою проблему в чате Докторли. Администратор подберет вам специалиста и
                организует чат с ним.
              </Text>
            </div>
          )}
          {type === SUBSCRIPTION_TYPE.DOCTOR && (
            <div>
              <Text size={14} lineHeight={21} weight={600}>
                2. Войдите в чат с врачом.
              </Text>
              <Text size={14} lineHeight={21}>
                Администратор автоматически создаст вам чат с врачом.
              </Text>
            </div>
          )}

          <div>
            <Text size={14} lineHeight={21} weight={600}>
              3. Пообщайтесь со специалистом.
            </Text>
            <Text size={14} lineHeight={21}>
              Общайтесь с врачом по интересующим вас вопросам. Опишите свое состояние и то что вас
              беспокоит.
            </Text>
          </div>

          {!subscriptionId && (
            <Text size={12} lineHeight={16} textAlign="center">
              Нажимая “Купить подписку”, вы соглашаетесь с условиями договора на оказание услуг
            </Text>
          )}
        </Wrapper>

        <Modal open={open} showCloseButton={false} size="small" title="Отменить подписку">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Text size={14} lineHeight={21}>
              Вы уверены, что хотите отменить подписку? Если вы отмените подписку, то потеряете
              неограниченный доступ к общение со специалистами.
            </Text>

            <AppButton
              title="Отменить подписку"
              bold
              onClick={cancelSubscriptions}
              color={COLORS.main_red}
            />
            <AppButton
              title="Продолжить использовать подписку"
              bold
              onClick={() => setOpen(false)}
            />
          </div>
        </Modal>

        <Bottom>
          <Select pickerValue={pickerValue} onChange={onChange} />
          {!subscriptionId && (
            <AppButton title="Купить подписку" bold fullWidth onClick={startYookassa} />
          )}
          {!!subscriptionId && (
            <AppButton title="Продлить подписку" bold fullWidth onClick={renewSubscriptions} />
          )}
          {!!subscriptionId && !isExpired && (
            <AppButton
              title="Отменить подписку"
              bold
              fullWidth
              onClick={() => setOpen(true)}
              color={COLORS.main_red}
            />
          )}
        </Bottom>
      </Container>
    </PageContainer>
  );
};
